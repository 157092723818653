.navBar {
  display: flex;
  height: 100%;
  align-items: flex-start;
  flex-shrink: 0;
}

.msg {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-grow: 1;
  height: 48px;
}
