.tokenLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
}

.loaderMsg {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  text-align: center;
  color: #5a5a5a;
}
