.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(74, 74, 74, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  min-width: 110px;
  min-height: 100px;
  max-width: 280px;
  max-height: 180px;
  padding: 16px;
  border-radius: 4px;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px #4a4a4a;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .modal {
    min-width: 180px;
    min-height: 180px;
    max-width: 400px;
    max-height: 300px;
  }
}
