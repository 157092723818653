@import "../../../Variables.scss";

#navBarItemBasic {
  display: flex;
  padding: 14px 60px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  height: 100%;
  border-radius: 0;
}
#navBarItem {
  @extend #navBarItemBasic;
  background-color: $nav-background-color;
  color: $nav-text-color;
}

#navBarItemSelected {
  @extend #navBarItemBasic;
  background-color: $nav-selected-background-color;
  color: $nav-selected-text-color;
}

#navBarItemHovered {
  @extend #navBarItemBasic;
  background-color: $nav-hovered-background-color;
  color: $nav-hovered-text-color;
}

#navBarItem:hover,
#navBarItemSelected:hover {
  background-color: $nav-hovered-background-color;
  color: $nav-hovered-text-color;
}

#navBarItemLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  overflow: auto;
  z-index: 1000;
  padding: 0px 1px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 0px 0px 4px 4px;
  border-right: 1px solid #b2b2b2;
  border-bottom: 1px solid #b2b2b2;
  border-left: 1px solid #b2b2b2;
  background-color: #fff;
  box-shadow: 0px 1px 1px 0px #b2b2b2;
  box-sizing: border-box;
}

.dropDownContentShow {
  display: block;
}

.subMenuLabel {
  display: flex;
  padding: 10px 59px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #005ba8 !important;
  font-family: "Roboto Flex";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  width: max-content;
  flex-grow: 1;
}

.subMenuLink {
  display: flex;
  text-decoration: none;
}

.subMenuLabel:hover {
  background-color: #ebf6ff;
}
