.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 32px;
  padding: 0 4px;
  column-gap: 96px;
}

.filterFunnel {
  display: flex;
  cursor: pointer;
  color: #005ba8;
}

.filterFunnel:hover {
  opacity: 0.5;
}

.filterTitle {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  flex-grow: 1;
  column-gap: 8px;
}

.filterHeader,
.filterSeparate {
  color: #005ba8;
  font-weight: 600;
}

.filterText {
  color: #000;
  font-weight: 400;
  white-space: nowrap;
  max-width: 10vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filterValue {
  display: flex;
  flex-direction: row;
  column-gap: 2px;
}

@media (min-width: 480px) {
  .filterText {
    max-width: 15vw;
  }
}

@media (min-width: 768px) {
  .filterText {
    max-width: 25vw;
  }
}

@media (min-width: 1024px) {
  .filterText {
    max-width: 35vw;
  }
}

@media (min-width: 1920px) {
  .filterText {
    max-width: 40vw;
  }
}
