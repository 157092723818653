.nativeGroupContainer {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  color: #005ba8;
  border-top: 1px solid #b2b2b2;
  margin: 4px 8px;
}

.nativeGroup {
  display: flex;
  padding: 12px 12px 0px 12px;
  margin-top: -8px;
  margin-left: -12px;
}

.nativeVisualsContainer {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 8px;
  row-gap: 8px;
  padding: 8px;
  max-width: 100vw;
}

.nativeVisual {
  height: 112px;
  margin: 1px;
  display: block;
  cursor: pointer;
}

.border {
  border: 1px solid #b2b2b2;
  border-radius: 4px;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 999;
  transition: visibility 0.3s ease;
}

@media (min-width: 480px) {
  .nativeVisualsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .nativeVisualsContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  .nativeVisualsContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1920px) {
  .nativeVisualsContainer {
    grid-template-columns: repeat(5, 1fr);
  }
}
