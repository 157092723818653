.kpiDashboardDossierContainer {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  color: #005ba8;
  flex-grow: 1;
  border-top: 1px solid #b2b2b2;
  margin: 20px;
}

.kpiDashboardDossierModule {
  display: flex;
  padding: 10px;
  margin-top: -5px;
  margin-left: -25px;
}

.kpiDashboardDossier {
  display: flex;
  flex-direction: column;
  min-height: 1000px;
}
