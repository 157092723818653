@import "/src/Variables.scss";
.footerContainer {
  height: 32px;
  flex-shrink: 0;
  background-color: $nav-background-color;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 8px;
}
