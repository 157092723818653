.errorContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  min-height: 100vh;
}

.errorLink {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.prevLink {
  font-size: 18px;
  font-family: "Roboto Flex";
  font-weight: 400;
  text-decoration: none;
  padding: 12px;
  color: #005BA8;
}
