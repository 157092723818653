.visualMaximizedDashboardDossierContainer {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  color: #005ba8;
  flex-grow: 1;
  border-top: 1px solid #b2b2b2;
  margin: 20px;
}

.visualMaximizedDashboardDossierModule {
  display: flex;
  padding: 10px;
  margin-top: -5px;
  margin-left: -25px;
}

.visualMaximizedDashboardDossier {
  display: flex;
  flex-direction: column;
  margin: 10px 20px;
  border-top: 1px solid #b2b2b2;
}

.visualMaximizedDashboardDossierHeader {
  display: flex;
  align-self: flex-start;
  margin: 5px -15px;
  font-size: 16px;
  font-weight: 500;
}


.visualMaximizedDashboardDossierVisual {
  display: block;
}




